(function() {
    'use strict';

    angular
        .module('capacitacionCertificacionApp')
        .controller('CertificationResultController', CertificationResultController);

    CertificationResultController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'JhiLanguageService', 'entity', 'User', '$window', '$state' ];

    function CertificationResultController($scope, $rootScope, $stateParams, previousState, DataUtils, JhiLanguageService, entity, User, $window, $state) {
        var vm = this;

        if (!User.current || User.current.result == undefined) {
            $state.go('certification.enrollment');
        } else {

            // TODO: Only for demo purpose:
            console.log(User.current)
            vm.examResult = User.current.examResult;
            if (!User.current) { User.current = {} }
            vm.certificate = {
                surname: User.current.surname,
                name: User.current.name,
                documentNumber: User.current.documentNumber,
                documentType: User.current.documentType,
                company: User.current.company,
                accessType: User.current.accessType,
                approved: (User.current.examResult.grade > 6),
                amountCorrect: User.current.examResult.grade,
                expirationDate: new Date().setYear(new Date().getYear() + 1),
                issueDate: new Date(),
                terminal: User.current.terminal == "Mejillones"? 'MEJILLONES' : 'SAN ANTONIO'
            };

            JhiLanguageService.getCurrent().then(function (language) {
                vm.certificateUrl = "https://capacitacion.odfjellterminals.com.ar/pdf/convert?paperSize=A4&zoomFactor=0.9&orientation=landscape&url=http://capacitacion.odfjellterminals.cl/certificate/" + vm.examResult.certificate.id + "?lang=" + language;
            });


        }

        console.log(vm)
        var unsubscribe = $rootScope.$on('capacitacionCertificacionApp:videoUpdate', function(event, result) {
            vm.video = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
