(function() {
    'use strict';

    angular
        .module('capacitacionCertificacionApp')
        .controller('CertificationCertificateAlreadyController', CertificationCertificateAlreadyController);

    CertificationCertificateAlreadyController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'JhiLanguageService', 'entity', 'User', '$window', '$state', 'Certificate' ];

    function CertificationCertificateAlreadyController($scope, $rootScope, $stateParams, previousState, DataUtils, JhiLanguageService, entity, User, $window, $state, Certificate) {
        var vm = this;
        vm.emailResent = false;

        if (!User.current) {
            $state.go('certification.enrollment');
        } else if(User.current.testInit) {
            $state.go('certification.questionnaire');
        } else {
            vm.status = {
                email: User.current.email
            }
            if (User.current.currentCertificateStatus.certificateExpiresInLessThan != null) {
                vm.status.showCertificateExpiresSoonWarning = true;
                vm.status.certificateExpiresInLessThanDays = User.current.currentCertificateStatus.certificateExpiresInLessThan;
            } else {
                vm.status.showCertificateExpiresSoonWarning = false;
            }
        }

        function resendCertificateByEmail() {
            console.log("Requesting to resend certificate to email...")

            // Redirect user to screen, based on certificates status.
            Certificate.resendCertificateByEmail({ terminal: User.current.terminal, userEmail: User.current.email })
            .$promise
            .then(function() {
                console.log('Email sent.')
                vm.emailResent = true;
            }).catch(function(error){
                console.log("Error:")
                console.log(error)
            });
        }

        vm.resendCertificateByEmail = resendCertificateByEmail;
    }
})();
