(function() {
    'use strict';

    angular
        .module('capacitacionCertificacionApp')
        .controller('CertificationCourseController', CertificationCourseController);

    CertificationCourseController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', '$sce', 'entity', 'User', '$state','JhiLanguageService'];

    function CertificationCourseController($scope, $rootScope, $stateParams, previousState, $sce, entity, User, $state, JhiLanguageService) {
        var vm = this;

        if (!User.current) {
            $state.go('certification.enrollment');
        } else {
            if(User.current.testInit) {
                $state.go('certification.questionnaire');
            }

            // This should be load from server from course's document.
            var videos = {
                'Mejillones': {
                    'es': 'http://capacitacion.odfjellterminals.cl/videos/odfjell_terquim_induccion_mejillones_es_1280x720_H264.mp4',
                    'en': 'http://capacitacion.odfjellterminals.cl/videos/odfjell_terquim_induccion_mejillones_en_1280x720_H264.mp4',
                    'pt-br':'http://capacitacion.odfjellterminals.cl/videos/odfjell_terquim_induccion_mejillones_es_1280x720_H264.mp4'},
                'SanAntonio': {
                    'es': 'http://capacitacion.odfjellterminals.cl/videos/odfjell_terquim_induccion_sa_es_1920x1080_H264.mp4',
                    'en': 'http://capacitacion.odfjellterminals.cl/videos/odfjell_terquim_induccion_sa_en_1920x1080_H264.mp4',
                    'pt-br':'http://capacitacion.odfjellterminals.cl/videos/odfjell_terquim_induccion_sa_es_1920x1080_H264.mp4'}
            };

            JhiLanguageService.getCurrent().then(function (language) {
                vm.video = {
                    sources: [
                        {src: $sce.trustAsResourceUrl(videos[User.current.terminal][language]), type: "video/mp4"},
                    ]
                };
            });
        }
        vm.certificate = entity;
        vm.previousState = previousState.name;
    }
})();
