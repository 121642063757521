(function() {
    'use strict';
    angular
        .module('capacitacionCertificacionApp')
        .factory('Certificate', Certificate);

    Certificate.$inject = ['$resource'];

    function Certificate ($resource) {
        var resourceUrl =  'api/certificate/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'hasCurrentlyValid': {
                url: 'api/certificate/has-currently-valid?userEmail=:userEmail&terminal=:terminal',
                method: 'GET',
                params: {terminal: '@terminal', userEmail: '@userEmail'},
                transformResponse: function (data) {
                    if (data) {
                      data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'resendCertificateByEmail': {
                url: 'api/certificate/resend-currently-valid?userEmail=:userEmail&terminal=:terminal',
                method: 'POST',
                params: {terminal: '@terminal', userEmail: '@userEmail'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
